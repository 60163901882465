$(document).ready(function() { 
    // Get all anchor links
    let anchorItems = $('a[href*="#"]');

    // Init scroll animation
    anchorList.init(anchorItems);

    // Get header
    let header = $('.header');
    let toggle = $('.header__nav__toggle');

    // Init mobile menu
    mobileMenu.init(header, toggle);

    // Init sticky header
    stickyHeader.init(header);
});

$(window).on('scroll resize', function () {
    // Get header
    let header = $('.header');

    // Update header
    stickyHeader.init(header);
});

let anchorList = {
    init: function (items) {
        items.on('click', function (e) {
            let target = $($(e.target).attr('href'));
            let totalHeight = $('html').height();
            let offsetTarget = target.offset().top - 96;
            let offsetViewport = $(window).scrollTop();
            let distanceToScroll = (offsetTarget > offsetViewport ? offsetTarget - offsetViewport : offsetViewport - offsetTarget);

            let timeToScroll = this.getTimeToScroll(distanceToScroll, totalHeight);

            // console.log('Viewport: ' + offsetViewport);
            // console.log('Target: ' + offsetTarget);
            // console.log('Distance to scroll: ' + distanceToScroll);
            // console.log('Time to scroll: ' + timeToScroll);

            $('html').animate({ scrollTop: offsetTarget }, timeToScroll);

            e.preventDefault();
        }.bind(this));
    },

    getTimeToScroll: function (distance, totalHeight) {
        return distance / totalHeight * 2500;
    }
}

let stickyHeader = {
    init: function (header) {
        if ($(window).scrollTop() >= 1) {
            $(".header").addClass("is-sticky");
        } else {
            $(".header").removeClass("is-sticky");
        }
    }
}

let mobileMenu = {
    init: function (header, toggle) {
        toggle.on('click', function (e) {
            header.toggleClass('menu-is-active');

            e.preventDefault();
        })
        // if ($(window).scrollTop() >= 1) {
        //     $(".header").addClass("is-sticky");
        // } else {
        //     $(".header").removeClass("is-sticky");
        // }
    }
}
